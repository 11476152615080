import { AppLoading } from '../src/components';
import { assets } from './assets';
import { configs } from './configs';
import { deviceControllers } from './deviceControllers';
import { plugins } from './plugins';

const blueBaseAppProps = {
	assets,
	configs,
	deviceControllers,
	plugins,

	LoadingComponent: AppLoading,
};

export default blueBaseAppProps;
