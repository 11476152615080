import {
	Body1,
	Card,
	Container,
	H5,
	//Link,
	ScrollView,
	View
} from '@bluebase/components';
import { Theme } from '@bluebase/core';
import * as React from 'react';
import { ViewStyle } from 'react-native';

export interface SupportProps {
	styles?: Partial<SupportStyles>;
}

export interface SupportStyles {
	container: ViewStyle;
	pageContainer: ViewStyle;
	rowStyles: ViewStyle;
	headerStyles: ViewStyle;
	closeButton: ViewStyle;
	p1: ViewStyle;
	h2: ViewStyle;
}
export const Support = (props: SupportProps) => {
	const styles = props.styles as any;
	return (
		<ScrollView>
			<Card>
				<Container style={styles.container}>
					<View style={styles.pageContainer}>
						<View style={styles.rowStyles}>
							<H5 style={styles.headerStyles}>Contact Us / Support</H5>
						</View>
						<Body1 style={styles.p1}>
							We are here to provide you with more information, answer any questions you may have
							and create an effective solution for your instructional needs.
						</Body1>
						<Body1 style={styles.p1}>You can contact our Customer Support at:</Body1>
						{/* <Link>
							<Body1 style={{ color: '#0275d8' }}>
								<Body1 style={{ color: '#0275d8' }}>(+92) 042-111-635-635</Body1>
							</Body1>
						</Link> */}
						<Body1 style={styles.p1}>Email:</Body1>
						{/* <Link>
							<Body1 style={{ color: '#0275d8' }}>help@mevris.com</Body1>
						</Link> */}
						<Body1 style={styles.container}>Monday-Friday 9:00 AM - 6:00 PM PKT</Body1>
						<Body1 style={styles.container}>Monday-Friday 9:00 AM - 6:00 PM PKT</Body1>
					</View>
				</Container>
			</Card>
		</ScrollView>
	);
};
Support.defaultStyles = (_theme: Theme): SupportStyles => ({
	closeButton: {
		alignSelf: 'center',
		display: 'flex',
		maxWidth: 0,
	},
	container: {
		padding: 10,
	},
	h2: {
		paddingBottom: 10,
		paddingTop: 11,
	},

	p1: {
		paddingTop: 20,
	},

	headerStyles: {
		display: 'flex',
		flex: 1,
	},
	pageContainer: {
		marginBottom: 50,
	},
	rowStyles: {
		display: 'flex',
		flexDirection: 'row',
	},
});
