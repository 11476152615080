// import 'react-native-gesture-handler';
import 'expo-asset';

import { BlueBaseApp } from '@bluebase/core';
import React from 'react';

import boot from './boot';
// import './sentry';

const App = () => <BlueBaseApp {...boot} />;
App.displayName = 'App';

export default App;
