/* eslint-disable react/no-unescaped-entities */
import {
	Body1,
	Card,
	H5,
	ScrollView,
	View
} from '@bluebase/components';
import { Theme } from '@bluebase/core';
import * as React from 'react';
import { ViewStyle } from 'react-native';

export interface PrivacyProps {
	styles?: Partial<PrivacyStyles>;
}

export interface PrivacyStyles {
	container: ViewStyle;
	pageContainer: ViewStyle;
	rowStyles: ViewStyle;
	headerStyles: ViewStyle;
	closeButton: ViewStyle;
	p1: ViewStyle;
	H5: ViewStyle;
}

export const Privacy = (props: PrivacyProps) => {
	const styles = props.styles as any;
	return (
		<ScrollView>
			<Card>
				<View style={styles.pageContainer}>
					<View style={styles.rowStyles}>
						<H5>Privacy Policy</H5>
					</View>

					<Body1 style={styles.p1}>
						This Application collects some Personal Data from its Users.
					</Body1>
					<H5 style={styles.H5}>Data Controller and Owner</H5>
					<Body1 style={styles.body}>
						Mevris is product of BlueEast Private Limited – a technology development wing of
						Orient Group of Companies.
					</Body1>

					<H5 style={styles.H5}>Types of Data collected</H5>
					<Body1 style={styles.body}>
						Among the types of Personal Data that this Application collects, by itself or through
						third parties, there are: first name, last name, email address, various types of Data,
						Cookies and Usage Data. Other Personal Data collected may be described in other
						sections of this privacy policy or by dedicated explanation text contextually with the
						Data collection. The Personal Data may be freely provided by the User, or collected
						automatically when using this Application. Any use of Cookies - or of other tracking
						tools - by this Application or by the owners of third party services used by this
						Application, unless stated otherwise, serves to identify Users and remember their
						preferences, for the sole purpose of providing the service required by the User.
						Failure to provide certain Personal Data may make it impossible for this Application
						to provide its services. Users are responsible for any Personal Data of third parties
						obtained, published or shared through this Application and confirm that they have the
						third party's consent to provide the Data to the Owner..
					</Body1>
					<H5 style={styles.H5}>Location tracking within the application</H5>
					<Body1 style={styles.body}>
						The user's location is required for as long as the application is running. The data
						collected is used to appoint places to the 'things' & monitor their environmental conditions.
						The application tracks the location in the background to gain insights about weather
						conditions and their subsequent impact on the overall running of the air conditioner
						and various connected devices in the application. These insights are used to provide
						subsequent reports and predictions.
					</Body1>
					<H5 style={styles.H5}>Mode and place of processing the Data</H5>
					<Body1 style={styles.body}>
						Methods of processing The Data Controller processes the Data of Users in a proper
						manner and shall take appropriate security measures to prevent unauthorized access,
						disclosure, modification, or unauthorized destruction of the Data. The Data processing
						is carried out using computers and/or IT enabled tools, following organizational
						procedures and modes strictly related to the purposes indicated. In addition to the
						Data Controller, in some cases, the Data may be accessible to certain types of persons
						in charge, involved with the operation of the site (administration, sales, marketing,
						legal, system administration) or external parties (such as third party technical
						service providers, mail carriers, hosting providers, IT companies, communications
						agencies) appointed, if necessary, as Data Processors by the Owner. The updated list
						of these parties may be requested from the Data Controller at any time. Place The Data
						is processed at the Data Controller's operating offices and in any other places where
						the parties involved with the processing are located. For further information, please
						contact the Data Controller. Retention time The Data is kept for the time necessary to
						provide the service requested by the User, or stated by the purposes outlined in this
						document, and the User can always request that the Data Controller suspend or remove
						the data. The use of the collected Data The Data concerning the User is collected to
						allow the Owner to provide its services, as well as for the following purposes:
						Contacting the User and Analytics. The Personal Data used for each purpose is outlined
						in the specific sections of this document. Detailed information on the processing of
						Personal Data Personal Data is collected for the following purposes and using the
						following services: Analytics The services contained in this section enable the Owner
						to monitor and analyze web traffic and can be used to keep track of User behavior.
						Cookie Policy This Application uses Cookies.
					</Body1>
					<H5 style={styles.H5}>Additional information about Data collection and processing</H5>
					<Body1 style={styles.body}>
						The User's Personal Data may be used for legal purposes by the Data Controller, in
						Court or in the stages leading to possible legal action arising from improper use of
						this Application or the related services. The User declares to be aware that the Data
						Controller may be required to reveal personal data upon request of public authorities.
						Legal action
					</Body1>

					<H5 style={styles.H5}>System logs and maintenance</H5>
					<Body1 style={styles.body}>
						For operation and maintenance purposes, this Application and any third party services
						may collect files that record interaction with this Application (System logs) or use
						for this purpose other Personal Data (such as IP Address).
					</Body1>

					<H5 style={styles.H5}>Information not contained in this policy</H5>
					<Body1 style={styles.body}>
						More details concerning the collection or processing of Personal Data may be requested
						from the Data Controller at any time. Please see the contact information at the
						beginning of this document.
					</Body1>

					<H5 style={styles.H5}>The rights of Users</H5>
					<Body1 style={styles.body}>
						Users have the right, at any time, to know whether their Personal Data has been stored
						and can consult the Data Controller to learn about their contents and origin, to
						verify their accuracy or to ask for them to be supplemented, cancelled, updated or
						corrected, or for their transformation into anonymous format or to block any data held
						in violation of the law, as well as to oppose their treatment for any and all
						legitimate reasons. Requests should be sent to the Data Controller at the contact
						information set out above.
					</Body1>

					<H5 style={styles.H5}>Changes to this privacy policy</H5>
					<Body1 style={styles.body}>
						The Data Controller reserves the right to make changes to this privacy policy at any
						time by giving notice to its Users on this page. It is strongly recommended to check
						this page often, referring to the date of the last modification listed at the bottom.
						If a User objects to any of the changes to the Policy, the User must cease using this
						Application and can request that the Data Controller remove the Personal Data. Unless
						stated otherwise, the then-current privacy policy applies to all Personal Data the
						Data Controller has about Users.
					</Body1>

					<H5 style={styles.H5}>Information about this privacy policy</H5>
					<Body1 style={styles.body}>
						The Data Controller is responsible for this privacy policy, prepared starting from the
						modules provided by Iubenda and hosted on Iubenda's servers.
					</Body1>

					<H5 style={styles.H5}>Definitions and legal references</H5>
					<Body1 style={styles.body}>
						Personal Data (or Data) Any information regarding a natural person, a legal person, an
						institution or an association, which is, or can be, identified, even indirectly, by
						reference to any other information, including a personal identification number. Usage
						Data Information collected automatically from this Application (or third party
						services employed in this Application), which can include: the IP addresses or domain
						names of the computers utilized by the Users who use this Application, the URI
						addresses (Uniform Resource Identifier), the time of the request, the method utilized
						to submit the request to the server, the size of the file received in response, the
						numerical code indicating the status of the server's answer (successful outcome,
						error, etc.), the country of origin, the features of the browser and the operating
						system utilized by the User, the various time details per visit (e.g., the time spent
						on each page within the Application) and the details about the path followed within
						the Application with special reference to the sequence of pages visited, and other
						parameters about the device operating system and/or the User's IT environment. User
						The individual using this Application, which must coincide with or be authorized by
						the Data Subject, to whom the Personal Data refers. Data Subject The legal or natural
						person to whom the Personal Data refers. Data Processor (or Data Supervisor) The
						natural person, legal person, public administration or any other body, association or
						organization authorized by the Data Controller to process the Personal Data in
						compliance with this privacy policy. Data Controller (or Owner) The natural person,
						legal person, public administration or any other body, association or organization
						with the right, also jointly with another Data Controller, to make decisions regarding
						the purposes, and the methods of processing of Personal Data and the means used,
						including the security measures concerning the operation and use of this Application.
						The Data Controller, unless otherwise specified, is the Owner of this Application.
						This Application The hardware or software tool by which the Personal Data of the User
						is collected. Cookies Small piece of data stored in the User's device. This privacy
						policy relates solely to this Application.
					</Body1>
				</View>
			</Card>
		</ScrollView>
	);
};
Privacy.defaultStyles = (_theme: Theme): PrivacyStyles => ({
	H5: {
		paddingBottom: 10,
		paddingTop: 11,
	},
	closeButton: {
		alignSelf: 'center',
		display: 'flex',
		maxWidth: 0,
	},
	container: {
		padding: 10,
	},

	p1: {
		paddingTop: 20,
	},

	headerStyles: {
		display: 'flex',
		flex: 1,
	},
	pageContainer: {
		marginBottom: 50,
	},
	rowStyles: {
		display: 'flex',
		flexDirection: 'row',
	},
});
