import {
	Body1,
	Card,
	H5,
	H6,
	ScrollView,
	Text,
	View
} from '@bluebase/components';
import { Theme } from '@bluebase/core';
import * as React from 'react';
import { TextStyle, ViewStyle } from 'react-native';

export interface TermsProps {
	styles?: Partial<TermsStyles>;
}

export interface TermsStyles {
	container: ViewStyle;
	pageContainer: ViewStyle;
	rowStyles: ViewStyle;
	headerStyles: ViewStyle;
	closeButton: ViewStyle;
	p1: ViewStyle;
	H5: ViewStyle;
	listStyle: ViewStyle;
	columnDirection: ViewStyle;
	boldFont: TextStyle;
	widthStyle: ViewStyle;
}
const list = [
	{
		boldText: 'Compliance with Laws: ',
		normalText: 'You may use the software product only for lawful purposes.',
		number: 'I. ',
	},
	{
		boldText: 'Limitations on Reverse Engineering: ',
		normalText:
			'You may not reverse engineer, decompile, or disassemble the software product, ' +
			'except and only to the extent that such activity is expressly permitted by ' +
			'applicable law notwithstanding this limitation.',
		number: 'II. ',
	},
	{
		boldText: 'Separation of Components: ',
		normalText:
			'The software product is licensed as a single product. ' +
			'Its component parts may not be separated for use on more than one computer.',
		number: 'III. ',
	},
	{
		boldText: 'Trademarks: ',
		normalText:
			'This EULA does not grant you any rights in connection with any trademarks or service marks of BLUEEAST Pvt. Ltd.',
		number: 'IV. ',
	},
	{
		boldText: 'Rental: ',
		normalText:
			'You may not sell, rent, lease, sublicense, lend or otherwise transfer' +
			' your rights to the Software Product.',
		number: 'V. ',
	},
	{
		boldText: 'Support Services: ',
		normalText:
			'BLUEEAST Pvt. Ltd. may provide you with support services related to the ' +
			'Software Product. Any supplemental software ' +
			'code provided to you as part of the support services shall be considered part ' +
			'of the software product and subject to the terms ' +
			'and conditions of this EULA. With respect to technical information you provide to ' +
			'BLUEEAST Pvt. Ltd. as part of the support services,' +
			' BLUEEAST Pvt. Ltd. may use such information for its business purposes, including for' +
			' product support and development. BLUEEAST Pvt.' +
			' Ltd. will not utilize such technical information in a form that personally identifies you.',
		number: 'VI. ',
	},
	{
		boldText: 'Software Transfer: ',
		normalText:
			'The initial licensee of the software product may make a one-time permanent transfer' +
			' of this EULA and software product only' +
			' directly to an end user. This transfer must include the entire software product' +
			' (including all component parts, any upgrades, this EULA).' +
			' The transferee of such one-time transfer must agree to comply with the terms of this EULA.',
		number: 'VII. ',
	},
	{
		boldText: 'Termination: ',
		normalText:
			'Without prejudice to any other rights, BLUEEAST Pvt. Ltd. may terminate this EULA if you fail to ' +
			'comply with the terms and' +
			' conditions of this EULA. In such event, you must destroy all copies of the ' +
			'software product and all of its component parts.',
		number: 'VIII. ',
	},
];

export const Terms = (props: TermsProps) => {
	const styles = props.styles as any;
	return (
		<ScrollView >
			<Card>
				<View style={styles.pageContainer}>
					<H5>End User License Agreement</H5>

					<Body1 style={styles.p1}>
						This copy of MEVRIS App(“the Software Product”) and accompanying documentation is
						licensed and not sold. This Software Product is protected by copy right laws and
						treaties, as well as laws and treaties related to other forms of intellectual
						property. BLUEEAST Pvt. Ltd. having its registered office at 26K.M., Multan Road,
						Lahore through its duly authorized officers. or its subsidiaries, affiliates, and
						suppliers (collectively “Licensee”) own intellectual property rights in the Software
						Product. The Licensee’s (“you” or “Your” license to download, use, copy, or change the
						Software Product is subject to these rights and to all the terms and conditions of
						this End User License Agreement (“Agreement”).
					</Body1>
					<H5 style={{ marginTop:15 }}>Your acceptance of Alexa Terms  </H5>
					<Body1 style={styles.p1}>
						Welcome to the Alexa skill. These terms and conditions (the “Terms”) apply to your use
						of the Skill. By using the Skill, you agree to be bound by these Terms. Use of the Skill
						is also governed by Amazon’s Term of Use -https://www.amazon.in/gp/help/customer/display.html?nodeId=201809740
						You must accept terms and conditions from Amazon (including for Alexa) and the Amazon Alexa
						app (the “Amazon Terms”) before using the Skill.
					</Body1>

					<H6 style={{ marginTop:10 }}>Using the Skill </H6>
					<Body1 style={styles.p1}>
						Using the Skill By enabling the skill you are consenting to sharing general information
						with Amazon Alexa and the Amazon Alexa app for you to interact through a voice interaction
						with Alexa. In order to use the Skill, you must activate it on the Amazon Alexa app
						(you can switch it on or off at any time).there is no age limit to use this app.
					</Body1>

					<H6 style={{ marginTop:10 }}>Rights</H6>
					<Body1 style={styles.p1}>
					RightsUse of the Skill through Amazon Alexa and the Amazon Alexa app is for your own personal
					non-commercial use only. I own all proprietary rights in the Skill. You will not have any right
					to the Skill apart from the right to use the Skill in line with these terms. In particular, you
					understand and agree that:
					(i) You are not permitted to resell, copy, or distribute the Skill for any commercial purposes
					or use the Skill in any other manner inconsistent with these terms;
					(ii) you are not permitted to duplicate, transfer, copy or distribute any part of the Skill in
					any medium without our prior written authorisation;
					(iii) you are not permitted to reverse engineer, alter or modify any part of the Skill;
					(iv) you will otherwise comply with these terms. Nothing in these terms affects any legal
					rights you have as a consumer.
					</Body1>

					<H6 style={{ marginTop:10 }}>Your information</H6>
					<Body1 style={styles.p1}>
					Your information When you use the Skill, the required information will be obtained by Amazon.
					Amazon will turn that information into an Alexa voice interaction which will then be retained
					by Amazon to improve the quality of Alexa’s voice interactions. The retention and use of that
					information will also be subject to the Amazon Terms and Amazon’s privacy policy. You may be
					required to complete an initial authentication process when enabling the Skill for the first
					time using a WiFi connection.
					</Body1>

					<H6>Cost</H6>
					<Body1 style={styles.p1}>
					Cost The Skill is provided to you free of charge. Unless you are using a WiFi connection,
					you may be charged according to your mobile plan for the use of data to enable and activate
					the Skill through the Amazon Alexa app.
					</Body1>

					<H6 style={{ marginTop:10 }}>Ending or changing the Skill </H6>
					<Body1 style={styles.p1}>
					Ending or changing the Skill You can stop using the Skill at any time or deactivate the Skill
					on the Amazon Alexa app or web page. I may suspend or change the Skill immediately for any
					reason, including where repair or maintenance work is needed. We may add new capabilities
					from time to time and have no obligation to offer any particular features in relation to
					the Skill. We can withdraw the Skill.
					</Body1>

      		<H6>What I am not responsible for </H6>
					<Body1 style={styles.p1}>
					What I am not responsible for I have provided the Skill on an “as is” basis for your personal
					use only.You must notify me immediately of any breach of security or unauthorized use of your
					information. I am not responsible for any errors or omissions in relation to Amazon Alexa or
					the Amazon Alexa app on behalf of Amazon. Neither I warrant that the Skill will meet your
					requirements or that the operation of the Skill will be uninterrupted or error free. To the
					extent permitted by law I disclaim and exclude all warranties, representations, conditions
					and other terms of any kind, express or implied by law or otherwise.
					</Body1>
					<H6>What I am not responsible for </H6>
					<Body1 style={styles.p1}>
					General terms I may allow another person to perform any of our obligations under these terms
					on my behalf. You agree that I may transfer my rights and obligations under these terms to
					another person. If I transfer my rights and obligations to any other third party, I will let
					you know. If any of these terms cannot be enforced by any court or other authority, I will
					delete it from these terms and it will not affect the rest of the terms.
					</Body1>

					<H5 style={styles.H5}>Acceptance</H5>
					<Body1 style={styles.body}>
						You accept and agree to be bound by the terms of this agreement by selecting the
						“accept” option and downloading the software product or by installing, using, or
						copying the software product. You must agree to all of the terms of this agreement
						before you will be allowed to download the software product. If you do not agree to
						all of the terms of this agreement, you must select “decline” and you must not
						install, use or copy the software product.
					</Body1>

					<H5 style={styles.H5}>License Grant</H5>
					<Body1 style={styles.body}>
						This Agreement entitles you to install and use one copy of the Software Product. In
						addition, you may make one archival copy of the Software Product. The archival copy
						must be on a storage medium other than a hard drive, and may only be used for the
						reinstallation of the Software Product. This Agreement does not permit the
						installation or use of multiple copies of the Software Product, or the installation of
						the Software Product on more than one computer at any given time, on a system that
						allows shared use of application, on a multi-users network, or on any configuration or
						system of computers that allows multiple users. Multiple copy use or installation is
						only allowed if you obtain an appropriate licensing agreement for each user and each
						copy of the Software Product.
					</Body1>
					<H5 style={styles.H5}>Description of other Rights and Limitation</H5>

					{list.map((item, i) => (
						<List key={i} {...item} />
					))}
					<H5 style={styles.H5}>Restrictions on Transfer</H5>
					<Body1 style={styles.body}>
						Without first obtaining the express written consent of BLUEEAST Pvt. Ltd. , you may
						not assign your rights and obligations under this Agreement, or redistribute,
						encumber, sell, rent, lease, sublicense, or otherwise transfer your rights to the
						Software Product.
					</Body1>

					<H5 style={styles.H5}>Limitation of Liability</H5>
					<Body1 style={styles.body}>
						To the maximum extent permitted by applicable law, in no event shall BLUEEAST be
						liable for any special, incidental, indirect, or consequential damages whatsoever
						(including, without limitation, damages for loss of business profits, business
						interruption, loss of business information, or any other pecuniary loss) arising out
						of the use of or inability to use the software product or the provision of or failure
						to provide support services. No liability shall be carried by BLUEEAST Pvt. Ltd.
						related to accuracy of the data or any damage to end user occurring due to use of this
						data by end user. Furthermore, no liability shall be carried by BLUEEAST Pvt. Ltd.
						related to the unavailability of cloud server and/or app due to maintenance or any
						other reason.
					</Body1>
				</View>
			</Card>
		</ScrollView>
	);
};

// tslint:disable-next-line: variable-name
export const List = ({ number, boldText, normalText }: any, props: TermsProps) => {
	const styles = props.styles || ({} as any);
	return (
		<View style={styles.listStyle}>
			<View style={styles.widthStyle}>
				<View>
					<Body1 style={styles.columnDirection}>
						<H6>{number}</H6>
						<Body1 style={styles.boldFont}>
							{boldText}
							<Text>{normalText}</Text>
						</Body1>
					</Body1>
				</View>
			</View>
		</View>
	);
};
Terms.defaultStyles = (_theme: Theme): TermsStyles => ({
	H5: {
		paddingBottom: 10,
		paddingTop: 11,
	},
	boldFont: {
		display: 'flex',
		flexWrap: 'wrap',
		fontWeight: 'bold',
	},
	closeButton: {
		alignSelf: 'center',
		display: 'flex',
		maxWidth: 0,
	},
	columnDirection: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
	},
	container: {
		padding: 10,
	},
	headerStyles: {
		display: 'flex',
		flex: 1,
	},
	listStyle: {
		flexDirection: 'row',
		paddingTop: 4,
	},

	p1: {
		paddingTop: 20,
	},
	pageContainer: {
		marginBottom: 50,
		padding:10,
	},
	rowStyles: {
		display: 'flex',
		flexDirection: 'row',
	},
	widthStyle: {
		width: 25,
	},
});
