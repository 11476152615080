import { BlueBaseAppLoading, BlueBaseAppLoadingProps } from '@bluebase/core';
import React from 'react';
import { Platform } from 'react-native';

// tslint:disable-next-line: no-var-requires
const logo = require('../../../assets/icon.png');

export const AppLoading = (props: BlueBaseAppLoadingProps) => {
	return (
		<BlueBaseAppLoading {...props} image={Platform.OS === 'web' ? { uri: logo } : (logo as any)} />
	);
};
