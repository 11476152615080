
export const plugins = [
	// ////////////////
	// UI Providers //
	// ////////////////

	// Main UI Kit
	import('@bluebase/plugin-material-ui'),

	// Routing
	import('@bluebase/plugin-react-navigation'),

	// Launcher Home Screen
	import('@bluebase/plugin-launcher'),

	// Maps!
	// import('@bluebase/plugin-mapview'),

	// Responsive Layouts and Grid System
	import('@bluebase/plugin-responsive-grid'),

	// Commi  Components
	import('@commi/client-plugin-ui'),

	// Icons!
	import('@bluebase/plugin-vector-icons/dist/MaterialCommunityIcons'),

	// Skeletons and placeholder!
	import('@bluebase/plugin-rn-placeholder'),

	// Permission Components
	import('@blueeast/client-plugin-permissions-ui'),

	// Permission Components
	import('@blueeast/client-plugin-location-ui'),

	// Mevris UI, a collection of common UI components
	import('@blueeast/client-plugin-ui'),

	// Mevris UI, a collection of common UI components
	import('@mevris/client-plugin-ui'),

	// ////////////////
	// Integrations //
	// ////////////////

	// Apollo Graphql Integration
	import('@bluebase/plugin-apollo'),

	// Apollo Subscriptions
	import('@bluebase/plugin-apollo-link-ws'),

	// App state persist
	import('@bluebase/plugin-apollo-cache-persist'),

	// Client Plugin Filters
	import('@blueeast/client-plugin-filters'),

	// Plugin to configure Apollo persist
	import('@bluebase/plugin-config-persist'),

	// Json Forms & Layouts
	import('@bluebase/plugin-json-graphql-components'),
	import('@bluebase/plugin-json-schema-components'),

	// Feedback plugin
	// import('@mevris/client-plugin-feedback'),

	// Sentry Error Reporting
	// import('@bluebase/plugin-sentry'),

	// ////////////////////
	// // Device Drivers //
	// ////////////////////

	// Controller Devices Locally
	import('@mevris/client-plugin-device-controllers'),

	// Tuya AddDevice Flow
	import('@mevris/client-plugin-installation-components'),

	// ////////////////////////////
	// Apps (Sequence Matters) //
	// ///////////////////////////

	// Things App
	import('@mevris/client-app-things'),

	// Products App
	import('@mevris/client-app-products'),

	// Automation App
	import('@mevris/client-app-automation'),

	// Settings App
	import('@bluebase/plugin-settings-app'),

	// //////////
	// Addons //
	// //////////

	// Authentication
	import('@blueeast/client-plugin-user-management'),

	// Provide's apps main navigation UI
	import('@mevris/client-plugin-taskbar'),

	// Reports
	import('@mevris/client-plugin-reports'),

	// Timeline & Activity Streams
	import('@mevris/client-plugin-activity-streams'),

	// Thing Diagnostic Information
	import('@mevris/client-plugin-diagnostics'),

	import('../src/index'),
];
